import React, { useState } from 'react';
import { Layout, SEO, AllPosts, CategoryNav } from 'components';
import { graphql } from 'gatsby';
import SearchBar from '../components/Faqs/SearchBar';
import './blog.scss';

export const Blog = props => {
  const { location, data } = props;
  // destructuring query
  const { posts } = data;
  const { nodes } = posts;

  const paginationAmount = 4;

  // search bar

  const [visiblePosts, setVisiblePosts] = useState(nodes ? [...nodes].splice(0, paginationAmount) : []); // set visible posts
  const [numberOfPosts, setNumberOfPosts] = useState(paginationAmount); // set search query
  const [searchQuery, setSearchQuery] = useState(''); // set search query
  const [searchActive, setSearchActive] = useState(false); // set search activate

  const setSearchHandler = event => {
    const query = event.target.value.toLowerCase() || '';
    setSearchQuery(query);
    queryQuestions(query);
  };

  const queryQuestions = query => {
    const filteredPosts = [...nodes].filter(blogItem => {
      const queryMatches = query ? blogItem.data.post_title.text.toLowerCase().includes(query) : true;
      return queryMatches;
    });
    setVisiblePosts(filteredPosts);
  };

  const searchFocusHandler = (event, clicked) => {
    event.preventDefault();
    setSearchActive(clicked);
  };

  const handleLoadMore = () => {
    const newNumberOfPosts = numberOfPosts + paginationAmount;
    setNumberOfPosts(newNumberOfPosts);
    setVisiblePosts([...nodes].splice(0, newNumberOfPosts));
  };

  // render
  return (
    <Layout location={location}>
      <SEO title="Welcome to the Safedome Blog" />
      <div className="blog">
        <div className="blog-wrapper">
          <h1 className="blog-title">Welcome to the Safedome Blog</h1>
          <p className="blog-description">
            Pastry croissant lemon drops jelly-o biscuit toffee candy canes. Bear claw cupcake cake. Pie cake fruitcake
            fruitcake marshmallow bear claw cupcake sweet roll lollipop dessert. Chocolate cake powder toffee powder
            cotton candy cotton candy cotton candy gummi bears bonbon bear claw. Marshmallow liquorice cake powder
            chocolate bonbon. Jelly tootsie roll chocolate bar chocolate jelly biscuit pastry sweet roll. Sweet Sweet
            Sweet roll croissant sweet chupa chups oat cake jelly chupa chups chupa chups. Pudding carrot cake topping
            jelly-o. Apple pie dessert gummies. Jelly tart dragée sugar plum sesame snaps. Halvah chocolate bar chupa
            chups dragée cotton candy bear claw. Croissant marshmallow gummies chocolate.
          </p>
        </div>
        <CategoryNav />
        <SearchBar
          searchActive={searchActive}
          searchQuery={searchQuery}
          setSearchHandler={setSearchHandler}
          searchFocusHandler={searchFocusHandler}
        />
        {/* <Categories activeCategory={activeCategory} categories={categories} setCategoryHandler={setCategoryHandler} /> */}
        <AllPosts posts={visiblePosts} onLoadMore={handleLoadMore} numberOfPosts={numberOfPosts} />
      </div>
    </Layout>
  );
};

export default Blog;

export const blogHomeQuery = graphql`
  query BlogHomeQuery {
    posts: allPrismicBlogPost(sort: { order: DESC, fields: data___date_published }) {
      nodes {
        uid
        data {
          featured_image {
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          category
          post_description {
            text
          }
          post_title {
            text
          }
        }
      }
    }
  }
`;
